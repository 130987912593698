import "../../css/article.css"
import "../../css/images.css"

import titleTemplate from "../../components/articleComponents/titleTemplate"
import bodyTemplate from "../../components/articleComponents/bodyTemplate"
import quotesTemplate from "../../components/articleComponents/quotesTemplate"
import soccer from "../../assets/ArticlePics/soccer.jpg"

const category = "PROSE";
const title = "My Old Friend";
const author = "BY: AUDREY XIA";
const date = "November 15, 2023";
const headline = ["One to zero."];
const divider = ["***"]
const ending  = [" ", "*"]
const paragraph1 = [];
const paragraph2 = [];
const paragraph3 = [];
const paragraph4 = [];
const paragraph5 = [];
const paragraph6 = [];
paragraph1[0] = "On Sunday, during a sweltering California heat wave, my middle school soccer team struggled against an insurmountable iron wall. We were seventy minutes in and down one point. Despite all our offensive strategies, nothing seemed to go through; the defensive forces we were up against seemed to slice through our tactics like a carefully sharpened knife, while our own defenders scrambled to patch up holes in response to their counterattacks."
paragraph1[1] = "Nothing seemed to be going right for me either. As a left-back defender, attacks that I would normally stop seemed to slip through my feet. Passes went awry, my footwork was shaky, and my receives were always a little too far out. I could feel sweat dripping down my face, surely rendering my sunscreen useless, and my mouth felt so dry and parched that I felt that my tongue was rotting in my mouth. Everything I saw seemed to be cast through the filter of an unfocused camera, as if heat was rising from the ground and casting smoke across the field. I tried to clear my head and tune into the Zen that an athlete can enter; the moment when the ball becomes one with your body, and your field of vision and spatial awareness becomes perfectly in tune with the natural environment. But my brain was full of noise -- of how the sidelines would assess my playing, if my coach would continue to start me, and if my teammates thought I was deadweight."
paragraph1[2] = "I tried to watch the field. The play was all happening on the right side of the field, far away from my domain. I attempted to relax, but the sun stabbed my eyes and thoughts burned through my skull."
paragraph1[3] = "Suddenly, an opponent launched a pass in the air toward the left-hand side of the field toward me, and an attacker came running straight toward me, hot on the ball’s trajectory. Our defenders on the right side had overcommitted, our midfielders were too tired to help drop back. I looked behind me in shock to realize that there was nothing but an open expanse of yellowed grass. Our keeper stood there, the single human in the entire stretch of grass before the goal, looking like prey caught in a trap as a hunter advances. Panic sharpened my focus. If I didn’t stop this attack, it was just our keeper and the goal. It was me against all these incoming attackers."
paragraph1[4] = "“You have to get to the ball first when it drops!” my left center back, Emma, called out. She was tall, blonde-haired, and blue-eyed, and frankly, I was jealous of her because she was conventionally pretty and insanely good at soccer. I always trusted her judgment."
paragraph1[5] = "I was always horrible at in-air battles, mainly because I avoided heading the ball to preserve brain cells for school. I felt the pressure mounting on me to save this play; I felt like Atlas, carrying the weight of the world, except I was crumbling under my own stupid thoughts."
paragraph1[6] = "I tried to read where the ball was going, and where the best point to receive the ball was before the other attacker could get it. The sun was unforgivably boring into my eyes. People were yelling at me to win the ball. My own brain was somewhere far away, lost in thoughts that had nothing to do with what was in front of me."
paragraph1[7] = "I don’t quite remember what happened. I know I just missed it. All I can remember is that one moment the ball was in front of me, in reach, and the next moment, and the ball and the attacker were behind me. The attacker made her shot, a clean hit that cut through the air like a bullet. Numbly, I heard the cheering on the opponent team’s sideline, the team celebrating on the field. In the periphery of my vision, I could see Emma shaking her head, and further out, Coach Bob scribbling angrily into a clipboard. I hadn’t tried hard enough. I had failed my team."

paragraph2[0] = "I don’t remember my first experience with soccer ever, or how it really all began. All I really remember is that at some point, me without a soccer ball was a concept that was unthinkable. It’s funny saying that now, because soccer is a sport and the singular thing that I probably have had the most complicated relationship with."
paragraph2[1] = "It didn’t really start out as a passion for me. It was just something I did because I found it fun as a little five-year-old growing up -- I enjoyed the team camaraderie, the excitement of simply kicking a ball and seeing where it would go, the joy when you made a particularly good play. I was happy when I got congratulated, understanding when I got criticisms, and forgetful when it came to bad days. Maybe as a kid, I understood soccer the best -- I loved the sport for what it was, and everything else was irrelevant outside of this special world I could indulge in for an hour and a half: myself, the ball, and the game."

paragraph3[0] = "Coming off the field after that bad game, Coach Bob, a middle-aged man with salt and pepper hair shorn short and a prickly beard, told us to gather up for feedback. Our entire team slowly and reluctantly shuffled around Coach Bob. Standing there with my teammates and my coach whom I had let down, I wanted to disappear -- to slowly fade into the landscape or melt into the dead grass until it was as if I were never there. Shame burned through every cell of my body, as I replayed my failure over and over in my head -- the ball being in front of me and then suddenly no longer."
paragraph3[1] = "I didn’t hear much of anything that my coach said, the ears tuned out to the world outside of me. I was lost in my own self-deprecating world; the internal punishments doled out there were more severe than anything a coach or teammate could tell me. "
paragraph3[2] = "I was only roused out of my self-created world of pain when my coach called out my name."
paragraph3[3] = "“And now, Audrey.”"
paragraph3[4] = "I hung my head down. If only there was a way to fold into myself and disappear like a real-life rendition of Picasso’s paintings."
paragraph3[5] = "“Now the goal they scored last wasn’t completely your fault -- the rest of the defensive line should have pushed back faster to come support, and midfield should have dropped back. But -- you pushed up way too far to be in a good position to receive, and you got caught unprepared. It’s not like you don’t know how to respond when you’re in a position like that -- it’s like you’re overthinking things and forget all your defensive basics. You’re also weak with air balls - it’s always being first to the ball. But you’re always scared, you second-guess, and you don’t get the ball -- it’s like you don’t believe in yourself.”"
paragraph3[6] = <div>The words passed into one ear and out another. I heard the words <em>unprepared, forget defensive basics, weak, scared,</em> and felt more like dying right there and then.</div>
paragraph3[7] = "After the huddle, Coach Bob gave everyone a high-five. He offered one to me, but I refused it."
paragraph3[8] = "“Why won’t you high-five me?” he asked, one eyebrow raised."
paragraph3[9] = "“I don’t deserve it when I played horribly,” I said."
paragraph3[10] = "He put his hand down and scowled. He shook his head slowly, as if he was trying to comprehend my actions. “Audrey,” he said. “You can beat yourself all you want, but that doesn’t help you be better.”"

paragraph4[0] = "I guess he was right because I was always my worst critic but also never learned to pick myself up. I quit high school soccer after two years, an experience that always felt like I was a bug under a microscope, unable to deal with the pressures of needing to perform in front of a sizable audience and constantly under scrutiny for each move. The decision was made abruptly after the first round of tryouts for my junior year of high school; I’d returned to the car crying, my skin feeling like it was peeling from the burning eyes of all the coaches who had been judging us. My mom had asked me why I was doing this if I wasn’t happy at all, and the decision was made right there and then to quit. It wasn’t a painful goodbye at all; my teammates were racist, unable to believe that an Asian girl could be good at anything other than school, and I was happy to be relieved from the pervasive fear of losing a starting position. Regardless of all the justifications, I couldn’t help but feel that I was running away from a problem because I couldn’t face it fully. "
paragraph4[1] = "I quit my private teacher’s lessons without saying goodbye to him face to face, afraid to hear what parting words he’d have to say to me. Secretly, I felt a little bitter, perhaps contributing to the reason why I only wrote an email goodbye; I was the oldest player in the sessions I took, but I was constantly being outperformed by younger players who seemed to understand the ball intuitively. It wasn’t fair to him, and I still regret not thanking him face-to-face. "
paragraph4[2] = "When I got two concussions during club seasons, I would take a break longer than necessary, wanting an excuse to not have to fall into a world of self-doubt and hate. I would sit by the sidelines, marveling at the strange mixture of emotions that came to me. Part of me itched to join the field -- I wanted to jump off the bench and tell my coach, “I’m not concussed anymore! I feel great! Put me on!” Another part of me savored the view from the sidelines, where you sat in a position where you could judge without being asked to prove your own worth."
paragraph4[3] = <div>Perhaps because of all of this, when COVID-19 hit and my club soccer career -- and my soccer career in general -- ended without a proper send-off, I was secretly glad. I was happy to tuck away my soccer backpack into the dark corners of the garage, my soccer ball slowly deflating in its cloth jail cell as it collected dust. During a clean-out of my closet, I donated most of my jerseys, even the ones that held fond memories for me. <em>You’re done,</em> I’d said. <em>You’re free.</em> But even as I said this, it felt like it wasn’t completely the truth.</div>
paragraph4[4] = "When things began to slowly open up and the world slowly began to restart, my dad returned to his pick-up soccer games. Some days, he would ask if I wanted to go. I rejected each invitation swiftly. The mention of actually playing soccer with other people immediately induced fear in me -- I couldn’t stop thinking of judgmental stares and blaming eyes that had become its main association with the sport."
paragraph4[5] = "After the third rejected invitation, my dad asked, “I thought you loved soccer. Isn’t this the perfect opportunity to play after all this time?”"
paragraph4[6] = "“I realized I don’t like soccer, really,” I replied coolly. And even though I had said it confidently, something inside me recoiled at that statement. “Don’t like” and “soccer” didn’t belong in the same sentence; they never would. Because I loved soccer, and I hated it, and most of all, my cowardly self really did miss it. Because my body really had known the ball as if it were another limb; well, maybe my body didn’t know the ball as well as my other peers, who went on to play college soccer, but that didn’t matter. Because when I played soccer, it felt as if the ball had known no other person."

paragraph5[0] = "In the beginning of my junior year of college, or maybe even earlier than that, I began to miss team sports. Sometimes, I would walk by the Great Lawn or Brittingham and see people kicking a ball around when returning to my dorm. There were times where the ball would roll out of bounds near my vicinity. I would chase after the ball without consciously deciding to, excited to pass the ball back to the field."
paragraph5[1] = <div>In my linguistics class, I learned that no one ever really forgets a language they learned, even if they haven’t used it since they were a child. In class, we specifically explored the case of a woman born in the Cape Verde islands, who only spoke Portuguese until age 17. After moving to the United States, she stopped speaking it for 90 years until she suffered a minor stroke. After the stroke, she lost the ability to speak English, but could speak perfect Portuguese again. <em>The brain is a mysterious thing,</em> Professor Simpson said. <em>Memories and knowledge are never fully forgotten; they’re just buried deep in your brain, waiting for a reminder to remember it again.</em></div>
paragraph5[2] = "It must be something like that, where the sight of the ball in motion immediately gets my feet moving. Even with years of painful soccer masking the initial love I had for the sport, childlike joy possesses my feet at the sight of something as simple as a ball going out of bounds. 90 years could pass by, and I’d still chase a ball at first sight. Love is very simple, after all. Make something yours for a while, and somehow, you’ll carry it with you forever."
paragraph5[3] = <div>Even with consistent reminders of what I’d lost walking to and from class, I still ran away from soccer. I picked up open gym volleyball because I was still afraid. Standing there clueless on the volleyball court, I was humbled as people would correct my stance and posture. They would tell me I was standing at the wrong place in the back row; I should utilize my legs more for receiving; I wasn’t swinging my arms right for the three-step approach to spike. <em>Serving is hard,</em> they’d reassure me after several missed serves. <em>I used to coach a youth girls’ volleyball team, and they’d struggle for a couple months.</em> It was fun having to learn a sport from scratch. There were no expectations for me to be good at open gym, no pressure to be the best. There was only the expectation to enjoy your time on the court.</div>
paragraph5[4] = "During one of my first games at volleyball, a ball dropped two feet in front of me. Instead of shuffling my feet forward so I could use my arms to receive, my body reacted on its own, and my foot came out to pop the ball up in the air."
paragraph5[5] = <div>“You’re not a soccer player, Audrey!” my friends yelled at me. “Don’t use your feet!” <em>But I am,</em> the thought immediately jumped into my head. <em>I am a soccer player.</em> Despite it all, my foot came to meet the ball like the Portuguese instead of the English."</div>
paragraph5[6] = <div>There’s this scene in a volleyball manga that I like. Years after the characters graduate from high school and go their separate paths, Hinata, the main character, decides to go to Brazil to play beach volleyball and train to get into a Japanese club volleyball team. After a few years in Brazil, he bumps into an old rival named Oikawa, who is now on the national Argentina volleyball team and is passing by for work-related reasons. They decide to play beach volleyball together for the week that Oikawa is in town. Oikawa, a player who has always been consumed with self-doubt over his abilities, slowly adjusts to the new rules and playstyle of beach volleyball compared to its floor counterpart – a process that requires relearning the basics of the game. In the middle of a match, Oikawa ruminates on the pains of always aiming for peak performance and never feeling fully satisfied. After missing a hit from the opponent side, he sprawls across the sand and laughs. <em>Aiming for the peak means that there will be more hardships than successes. And sometimes it feels like you’re not trying if it isn’t hard. But despite that,</em> he thinks when picking himself off the ground, <em>from time to time, fun stuff comes. And that fun draws me in. It reminds me that I forgot that volleyball is fun.</em></div>

paragraph6[0] = "A month ago, I decided to return to soccer. Swallowing the fears that I had of being judged or being bad, I made up my mind to play pick-up soccer at Brittingham Field with a couple of friends I had made from open gym volleyball. Packing up my cleats and my water bottle in my old high school club soccer bag that morning, I noticed some of the pins that were still attached to the top of the bag -- small tokens from various tournaments that my team had placed well in. Instantly, I felt some strange kind of nostalgia of times passed that I would never get back, of some nebulous days in the past where I played without any kind of doubt. Just pure joy."
paragraph6[1] = "I skated to the field, where I was the first one from my volleyball friends to arrive. I sat down awkwardly by the sidelines, lacing up my shoes and observing people who were stretching and practicing -- a habit I had gotten from all the tryouts I had been to in the past, sizing up players to see who would be competition and who would be easy to squash. It didn’t take me long of watching people dribble and pass to know that the people who had showed up to play pick-up soccer were quite good, and I hadn’t played consistently in years. I slowly felt some nervousness building, but quickly brushed it aside."
paragraph6[2] = "On the actual soccer field, there were already a group of middle schoolers and high schoolers playing from the surrounding communities of USC."
paragraph6[3] = "“When will we be able to play?” someone grumbled. “These kids don’t even go to USC.”"
paragraph6[4] = "Everyone who was on the sidelines coalesced together in a little huddle on the sidelines, trying to figure out when we would be able to get on the field"
paragraph6[5] = "“Just kick them off,” someone said."
paragraph6[6] = "“Let’s call whoever manages the fields at USC to get them to leave,” someone unhelpfully offered. “You think that DPS officer would do something?”"
paragraph6[7] = "The DPS officer, who was standing further away in the corner of the ramp leading up to the field, looked at us, scowled, and then moved further down the ramp away from us."
paragraph6[8] = "“I don’t think we’re allowed to force them to leave, since this field is open use,” someone replied exasperatedly."
paragraph6[9] = "Finally, after some discussion, someone went out onto the field to talk to the kids, a tall blonde kid who had the loudest voice. He sauntered out to the middle of the field when a ball went out of bounds, motioning at them to bring it in. The group of kids seemed to collectively groan, but begrudgingly went to talk to our guy, their arms crossed as they all grouped around the center circle of the field. It was a humorous sight, seeing a six-foot guy surrounded by a bunch of under 5’6” boys; our representative looked somewhat of a referee, frustratingly disciplining an entire team. We could see hand waving and gestures, furious pointing, and the shaking of heads."
paragraph6[10] = "“The kids won’t get off!” our representative yelled over to us. By this time, we had already started creeping onto the field, as if we were little pieces in a Go game, taking over empty pockets of land."
paragraph6[11] = "More discussion. Finally, a negotiation to share the field was painstakingly pounded out -- the neighborhood kids and the college students were both divided into two teams. The team who scored two goals first would stay on, while the losing team would come off; a time limit of 10 minutes was given for each game, and if the points were tied, both teams would come off. We had agreed to let the kids play for five more minutes."
paragraph6[12] = "We all settled back onto the sidelines. One of the kids was wearing a familiar black jersey -- Pateadores, the same club team I used to be on in middle to high school. He was the best of the group, weaving through defenders carefreely, the ball one with his body. But the other players didn’t falter, faced with a superior player -- they laughed off mistakes, smiled at good plays, encouraged each other. I could tell that many of them had never gotten formal training, and even though they struggled, still got up and kept playing after a mistake or a tumble. They were having fun, I realized. I had forgotten what it meant to have fun in soccer. I thought of my high school days, where I was constantly defending my starting position, only for the last game of the season to lose that position; how I had cried every other day from some small or big mistake I had made; or the arguments I would get with my parents over why I bothered to keep playing if it had made me so unhappy."
paragraph6[13] = "The five-minute timer sounded. The blonde representative went onto the field and ushered the kids off, who grumbled but ultimately relented. The kid who had once been wearing the jersey I once proudly wore rolled his eyes and jogged off. I thought about the self-assured way he had played and wondered what I had looked like in the 16 years I had been in the sport. Had I ever looked that confident? At ease with my skills? Had I ever been simply content to be able to play?"
paragraph6[14] = "The kids shouted at us to get going -- they had started the timer and would not hesitate to kick us off the moment it sounded. I ran onto the field, feeling the familiar crunch of turf under my cleats, and my feet found the left back position unconsciously as if it was remembering an old home. The perspective of the field from this position, where it seemed like the whole field was opening ahead of me; the way the left sideline and its observers floated on the periphery of my vision; the presence of my fellow defenders offering quiet support to my right – how many games at different ages, times, and locations had I started the game exactly like this? The sensations of different days, happy and sad, rushed through me as if ascribing a singular purpose. Surreally, it felt like the field had been faithfully waiting for me, an old, estranged friend, to return."
paragraph6[15] = "The ball got passed to me. For a second, I wondered if I still remembered how to pass the ball, if it would still feel right against my foot. But even though I could feel that the old nimbleness of my feet was not quite there, my body kicked right back into action -- the same old peaceful concentration came back almost instantaneously, and the rest of the world with all its white noise seemed to fade away. It was only me, the game, and the ball. I felt like I had been inserted as a necessary part of a bigger whole - that this ten-minute game was its own little world with well-oiled pieces that knew exactly how to move, and that I was part of this thing that was about to come to life. Where I was standing, the exact way my muscles were tensing, the positioning of my feet -- it was all right. When the ball met my foot, I couldn’t help but smile. My body remembered. It knew the ball."

function Myoldfriend()
{
    return(
        <div className="container">
                <div className="row">
                    <div className="col">
                    </div>

                    <div className="col-8">
                        {titleTemplate(category, title, author, date)}
                        <div className="article-image row">
                            <img src={soccer} className="col-12"/>
                        </div>

                        {quotesTemplate(headline)}

                        {bodyTemplate(paragraph1)}

                        {quotesTemplate(divider)}
                        {bodyTemplate(paragraph2)}
                        {quotesTemplate(divider)}

                        {bodyTemplate(paragraph3)}
                        {quotesTemplate(divider)}


                        {bodyTemplate(paragraph4)}
                        {quotesTemplate(divider)}

                        {bodyTemplate(paragraph5)}
                        {quotesTemplate(divider)}
                        {bodyTemplate(paragraph6)}
                        {quotesTemplate(ending)}

                    </div>

                    <div className="col">
                    </div>
                </div>
        </div>
    );
}
export default Myoldfriend;