import HomeHero from "../components/homeComponents/homeHero";
import zineSP23 from "../assets/day_zine.pdf"
import zineSP24 from "../assets/zine24.pdf"
import { StorageImage } from '@aws-amplify/ui-react-storage';

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';

import React, {useState, useEffect} from 'react';
import { list } from 'aws-amplify/storage';

import '../css/magazine.css'

const bucketName = 'daywebsitereact-storage-4bc14e8991458-dev';
const objectKey = 'rodrigo_1.jpg';
const s3Url = `https://${bucketName}.s3.amazonaws.com/public/${objectKey}`;
let imageUrl = 'blah';

const ImageComponent = () => {
    Amplify.configure(awsconfig);
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      const getImageUrl = async () => {
        try {
            const result = await list({
                prefix: 'public/'
            });
            console.log(result);
            } catch (error) {
            console.log(error);
            }
        try {
          const url = await Storage.get('rodrigo_2.jpg', { level: 'guest' });
          setImageUrl(url);
        } catch (error) {
          console.error('Error getting image URL:', error);
        }
      };
  
      getImageUrl();
    }, []);
  
    return (
      <div>
        {imageUrl && (
          <img src={imageUrl} alt="cat" />
        )}
      </div>
    );
  };

function Magazine()
{
    fetch(s3Url)
    .then(response => {
        if (!response.ok) {
        throw new Error(`Failed to fetch S3 object: ${response.statusText}`);
        }
        return response.blob();
    })
    .then(blob => {
        // Handle the blob, e.g., display the image
        imageUrl = URL.createObjectURL(blob);
        console.log('Image URL:', imageUrl);
    })
    .catch(error => {
        console.error('Error fetching S3 object:', error);
    });
    return(
        <div>
            <div>
                {HomeHero("DAY MAGAZINE", "")}
            </div>
            <div className="magazine-body">
                Each semester, DAY composes a literary and art magazine featuring pieces
                made by USC students who are a part of the APIDA community. Our most recent
                magazine was released in May 2024, with theme "Lost and Found." The zine focuses
                on stories of reclaiming identity and finding belonging as Asians in America.
            </div>
            <div className="magazine-class">
                <object className="magazine-container" data={zineSP24} type="application/pdf"></object>
            </div>
            <div>
            <ImageComponent imgKey="rodrigo_2.jpg" />
            {/* Add more instances with different image keys if needed */}
            
            <div className="magazine-body">
            Our first magazine, "Roots," from April 2023 magazine reflects on our artists' 
            upbringings and how being a part of the APIDA community has shaped their lives, making
            them the people they are today.
            </div>
            <div className="magazine-class">
                <object className="magazine-container" data={zineSP23} type="application/pdf"></object>
            </div>
            <ImageComponent imgKey="rodrigo_2.jpg" />
            </div>
            
        </div>
    );
}
export default Magazine;