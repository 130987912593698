// for sources heading
import '../../css/article.css'

function sourcesTemplate(){
    return(
        <div>
            <div className="py-5">
            </div>
            
            <h4 className="article-sources">
                SOURCES
            </h4>
        </div>
  );

}

export default sourcesTemplate;