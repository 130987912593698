/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://slgcswq2prb4zih5feejfrplna.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rdedl3mvdjhp3epcpo5soktoou",
    "aws_cognito_identity_pool_id": "us-east-1:698d8e2c-4bc8-4d55-8bf6-1b2bd10196c3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_WVsC3dpLC",
    "aws_user_pools_web_client_id": "7ojd3anfvks6bbmrf5kcl9sk5n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "daywebsitereact-storage-4bc14e89122358-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
